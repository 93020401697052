import {MouseEventHandler} from "react";
import s from "styled-components";

interface NavToggleOptions {
  opened?: boolean;
  color?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function NavToggle(props: NavToggleOptions) {
  const {opened = false, color = "black"} = props;

  return (
    <Button {...props}>
      <Stack opened={opened}>
        <Line opened={opened} color={color} />
        <Line opened={opened} color={color} />
        <Line opened={opened} color={color} />
      </Stack>
    </Button>
  );
}

const Button = s.button`
display: none;
padding: 0;

background: transparent;
border: none;
cursor: pointer;
outline: none;

@media (max-width: ${props => props.theme.widths.medium}) {
  display: block;
}
`;

const Stack = s.div<{opened: boolean}>`
display: flex;
flex-direction: column;
justify-content: ${props => (props.opened ? "center" : "space-between")};

width: 30px;
height: 20px;

transition: all 0.3s ease;
transform: ${props => (props.opened ? "rotate(45deg)" : "none")};
`;

const Line = s.span<{opened: boolean}>`
display: block;
width: 100%;
background: ${props => props.color};
height: 1px;
border-radius: 1px;
transition: all 0.3s ease;

&:nth-child(1) {
  transform: ${props => (props.opened ? "scaleX(0)" : "scaleX(1)")};
}

&:nth-child(2) {
  transform: ${props => (props.opened ? "rotate(90deg)" : "none")};
}

&:nth-child(3) {
  transform: ${props => (props.opened ? "translateY(-1px)" : "none")};
}
`;
