import s, {keyframes} from "styled-components";

interface Props {
  size: number;
  color: string;
}

export function Spinner(props: Props) {
  return (
    <Container {...props}>
      <Blade {...props} i={1} />
      <Blade {...props} i={2} />
      <Blade {...props} i={3} />
      <Blade {...props} i={4} />
      <Blade {...props} i={5} />
      <Blade {...props} i={6} />
      <Blade {...props} i={7} />
      <Blade {...props} i={8} />
      <Blade {...props} i={9} />
      <Blade {...props} i={10} />
      <Blade {...props} i={11} />
      <Blade {...props} i={12} />
    </Container>
  );
}

const Container = s.span<Props>`
position: relative;
display: flex;
justify-content: center;
align-items: center;
width: ${props => props.size}px;
height: ${props => props.size}px;
`;

const fade = keyframes`
0% {
  opacity: 0.85;
}
50% {
  opacity: 0.25;
}
100% {
  opacity: 0.25;
}
`;

const Blade = s.span<{i: number} & Props>`
position: absolute;
display: block;
background: ${props => props.color};
width: ${props => Math.round(props.size * 0.25)}px;
height: 2px;

transform: rotate(${props => props.i * 30}deg) translateX(${props =>
  Math.round(props.size * 0.4)}px);

border-radius: 1px;

animation-fill-mode: both;
animation: ${fade} 1s ${props => props.i / 12}s infinite ease-in-out;
opacity: 0.25;
`;
