import React from "react";

const Instagram = () => {
  return (
    <svg
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="a"
          d="m0 6.4106502c0-3.54050434 2.86551237-6.4106502 6.4106502-6.4106502h7.1786996c3.5405043 0 6.4106502 2.86551237 6.4106502 6.4106502v7.1786996c0 3.5405043-2.8655124 6.4106502-6.4106502 6.4106502h-7.1786996c-3.54050434 0-6.4106502-2.8655124-6.4106502-6.4106502zm1.81034483-.01597429v7.21064819c0 2.526465 2.05247493 4.5843311 4.58433108 4.5843311h7.21064819c2.526465 0 4.5843311-2.052475 4.5843311-4.5843311v-7.21064819c0-2.52646505-2.052475-4.58433108-4.5843311-4.58433108h-7.21064819c-2.52646505 0-4.58433108 2.05247493-4.58433108 4.58433108zm8.18965517 8.69153099c-2.8090345 0-5.0862069-2.2771724-5.0862069-5.0862069s2.2771724-5.0862069 5.0862069-5.0862069 5.0862069 2.2771724 5.0862069 5.0862069-2.2771724 5.0862069-5.0862069 5.0862069zm0-1.8103448c1.8092087 0 3.2758621-1.4666534 3.2758621-3.2758621 0-1.80920866-1.4666534-3.27586207-3.2758621-3.27586207-1.80920866 0-3.27586207 1.46665341-3.27586207 3.27586207 0 1.8092087 1.46665341 3.2758621 3.27586207 3.2758621zm5.2586207-7.41379313c-.6665506 0-1.2068966-.540346-1.2068966-1.20689656s.540346-1.20689655 1.2068966-1.20689655c.6665505 0 1.2068965.54034599 1.2068965 1.20689655s-.540346 1.20689656-1.2068965 1.20689656z"
        />
        <filter id="b" height="115%" width="115%" x="-7.5%" y="-7.5%">
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation=".5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            type="matrix"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0624716938 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <use fill="#000" filter="url(#b)" xlinkHref="#a" />
        <use className="fill" fillRule="evenodd" xlinkHref="#a" />
      </g>
    </svg>
  );
};

export default React.memo(Instagram);
